import React from "react"
import styled from "styled-components"
import CombinedShape from "../../images/source/CombinedShape.svg"
import MobileShape from "../../images/source/MobileShape.png"
import medico1 from "../../images/source/vent-medico-1.png"
import logoTrinidad from "../../images/source/LOGO-TDP.png"

const DoctorCard = () => {
	return (
    <Container>
      <div className="container">
        <div className="container2">
          <CombinedShape className="shape" />
          <img src={MobileShape} alt="card" className="shape-mobile" />

          <div className='box overlay'>
            <h4 className="testimonios">Testimonios</h4>
            <p className="vent">
              “VentSupport nos ha hecho pensar y tener siempre presente que el paciente no tiene que estar 
              sedado o extremadamente sedado; sólo que ahora lo podemos ver en tiempo real. Y nos ha hecho 
              pensar lo potencialmente dañinas que son las asincronías; pero pudiendo verlas, medirlas y 
              corregirlas, para mejorar nuestro trabajo y que les vaya mejor a los pacientes.”
            </p>
            <img src={logoTrinidad} alt="logo trinidad" className="logo" />
            <p className="foot-text">
              Dr. Felipe Carvajal - MN: 151203 - Médico Intensivista <br />
              Coordinador Terapia Intensiva
            </p>
          </div>
          <img src={medico1} alt="doctor img" className='doc-img overlay'/>
        </div>
      </div>
    </Container>
	)
}

export default DoctorCard

const Container = styled.footer`
  .container {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin-bottom: 260px;
    margin-top: 180px;
    @media (max-width: 800px) {
      margin-bottom: 40px !important;
      margin-top: 40px;
    }
  }

  .container2 {
    position: relative;
  }

  .box {
    top: 84px;
    left: 600px;
    height: 445px;
    width: 482px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 800px) {
      top: 162px;
      left: 30px;
      width: 303px;
      max-height: 392px;
    }
  }
  
  .overlay {
    z-index: 9;
  }

  .doc-img {
    top: 45px;
    left: 45px;
    height: 508px;
    width: 508px;
    position: absolute;
    @media (max-width: 800px) {
      left: 109px;
      top: 15px;
      height: 134px;
      width: 134px;
    }
  }

  .testimonios {
    height: 40px;
    width: 400px;
    color: #19AE8C;
    font-family: "Century Gothic";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin: 0;
    @media (max-width: 800px) {
      font-size: 18px;
    }
  }

  .vent {
    color: #000000;
    font-family: "Century Gothic";
    font-style: italic;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .logo {
    margin: 0;
    width: 180px;
    @media (max-width: 800px) {
      width: 130px;
    }
  }

  .foot-text {
    height: 61px;
    width: 482px;
    color: #615E5E;
    font-family: "Century Gothic";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    max-width: fit-content;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .shape {
    @media (max-width: 800px) {
      display: none;
    }
  }

  .shape-mobile {
    @media (min-width: 800px) {
      display: none;
    }
  }
`
