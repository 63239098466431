import { navigate } from "gatsby-link";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components"
import Whatsapp from "../../images/svg/icon-whatsapp.svg"
import Loading from "../common/Loading"
import { TextField, Button } from "@material-ui/core"


const StyledTextField = styled(TextField)`
  ${props =>
        css`
      input {
        color: #000 !important;
      }
      div:after, div:before {
        border-bottom: none;
      }
      div {
        color: #000 !important;
        background-color: #ffffff;
        box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        &:hover {
            background-color: #ffffff;
            box-shadow: 2px 5px 14px 0 rgba(0,0,0,0.1);
        }
      }
      label {
        color: #000000;
        font-family: Raleway;
      }
      textarea {
        color: #000 !important;
      }
    `}
`
const URL = process.env.GATSBY_API_URL
//const URL = "http://localhost:6001"

const ContactForm = ({ location, title, title2, paddingTitle, page }) => {

    const [messageLength, setMessageLength] = useState()
    const [submitStatus, setSubmitStatus] = useState("standby")
    const [submitClick, setSubmitClick] = useState(false)
    const [sendData, setSendData] = useState()
    const { register, handleSubmit, errors, reset } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    })
    const onSubmit = data => {
        if(page) data.page = page;
        setSendData(data)
        setSubmitClick(true)
    }

    const url = `${URL}/contacto/`

    const fetchUrl = async () => {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(sendData),
        })
        const json = await response.json()
        if (json.error) {
            setSubmitStatus("error")
        } else {
            setSubmitStatus("send")
            navigate('/agradecimiento');
            reset(response)
        }
    }
    useEffect(() => {
        if (submitClick) {
            setSubmitStatus("working")
            fetchUrl()
            setSubmitClick(false)
        }
    }, [submitClick])

    const remainCharacter = value => {
        const maxChar = 600
        if (value === maxChar) {
            return "Limite de catacteres alcanzado"
        } else if (value >= maxChar - 100) {
            return maxChar - value
        }
    }

    return (
        <Styled paddingTitle={paddingTitle}>
            <div className="ContactForm">
                <>
                    <h2 className="title">
                        {title}
                    </h2>
                </>
                {title2 && (
                    <>
                        <h2 className="title2">
                            {title2}
                        </h2>
                    </>
                )}

                {/* <div className="linkAccess">
                    <div className="icon"> <Whatsapp /> </div>
                    <div className="desc"><p>Contáctanos a nuestro Whatsapp haciendo  <a href="https://api.whatsapp.com/send?phone=5491133101591" className="link-white"><b> click aquí +5491133101591</b></a></p>
                    </div>
                </div> */}

                <form
                    className="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="formControl">
                        <StyledTextField
                            label="Nombre / Empresa"
                            className="form-field"
                            inputRef={register({ required: true })}
                            name="name"
                            id="name"
                            inputProps={{ maxLength: 40 }}
                            placeholder="Escribe aquí"
                            size="small"
                            variant="filled"
                            fullWidth={true}
                            location={location}
                            helperText={errors.name ? "Campo obligatorio" : false}
                            error={errors.name ? true : false}
                        />
                    </div>
                    <div className="formControl">
                        <StyledTextField
                            label="Correo electrónico"
                            className="form-field"
                            inputRef={register({
                                required: "Required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                },
                            })}
                            name="mail"
                            id="mail"
                            placeholder="Escribe aquí"
                            size="small"
                            inputProps={{ maxLength: 50 }}
                            variant="filled"
                            fullWidth={true}
                            type="email"
                            location={location}
                            helperText={errors.mail && "Ingrese un formato de correo válido"}
                            error={errors.mail ? true : false}
                        />
                    </div>
                    <div className="formControl">
                        <StyledTextField
                            label="Teléfono"
                            className="form-field"
                            inputRef={register}
                            name="phone"
                            id="phone"
                            inputProps={{ maxLength: 20 }}
                            type="number"
                            placeholder="Escribe aquí"
                            size="small"
                            variant="filled"
                            fullWidth={true}
                            location={location}
                        />
                    </div>
                    <div className="formControl">
                        <StyledTextField
                            label="Mensaje"
                            className="form-field"
                            inputRef={register({ required: true, maxLength: 600 })}
                            name="message"
                            id="message"
                            placeholder="Escribe aquí"
                            size="small"
                            variant="filled"
                            inputProps={{ maxLength: 600 }}
                            fullWidth={true}
                            multiline
                            location={location}
                            rows="3"
                            onChange={e => setMessageLength(e.target.value.length)}
                            helperText={
                                errors.message &&
                                errors.message.type === "required" &&
                                "Campo obligatorio"
                            }
                            error={errors.message ? true : false}
                        />
                        {messageLength > 100 && (
                            <p className="falseError">{remainCharacter(messageLength)}</p>
                        )}
                    </div>
                    <Button
                        type="submit"
                        variant={"contained"}
                        color={"secondary"}
                        // disabled={formState.isValid ? false : true}
                        fullWidth={true}
                        className="submit"
                    >
                        {submitStatus === "working" ? (
                            <Loading
                                style={{
                                    height: "22px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                color={"rgba(0,0,0,.2)"}
                            />
                        ) : (
                            "ENVIAR"
                        )}
                    </Button>
                </form>
            </div>
        </Styled>
    )
}

const Styled = styled.div`
 .title {
    width: 100%;
    padding: ${props => props.paddingTitle ?  `${props.paddingTitle}`: '0px 70px'};
    color: #000000;
    font-family: Questrial;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    margin: 0;

    
    @media (max-width: 780px) {
        padding: 0px 10px;
    }
 }

 .linkAccess{
    margin: 0 auto;
    height: 67px;
    max-width: 500px;
    border-radius: 33.5px;
    background-color: #24CC65;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;

    .icon {
        width: 70px;
        margin-left: 5px;
        svg {
            height: 40px;
            width: 71.11px;
        }
    }

    .desc {

        padding-right: 15px;

        p {
            color: #FFFFFF;
            font-family: "Century Gothic";
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 17px;
            text-align: center;
            margin: 0;
            text-decoration: none;

            a {
                color: white !important;
            }
        }
    }
 }

 .formControl{
     min-height: 65px;     

     .form-field:after {
        border-bottom-color: #f44336 !important;
    }
 }
 .submit{
    color: #FFFFFF;
    font-family: "Century Gothic";
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    margin-top: 32px;
    height: 40px;
    background-color: #A5BE01;
 }

 .form {
    margin-bottom: 0;
 }
`

export default ContactForm;