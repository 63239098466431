import React from 'react'
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import logo from "../../images/source/logo_ventsupport.png"
import ContactForm from "../../components/landing/ContactForm"
import celulares from "../../images/source/vistas-celu.png"
import celularesDesktop from "../../images/source/Mobile.png"
import background from "../../images/source/background-vent-2.png"
import backgroundDesktop from "../../images/source/background-desktop-vent-1.png"
import background2 from "../../images/source/background-vent-3.png"
import background3 from "../../images/source/background-vent-4.png"
import background2Desktop from "../../images/source/background-desktop-vent-2.png"
import background3Desktop from "../../images/source/background-desktop-vent-4.png"
import laptop from "../../images/source/Laptop.png"
import DoctorCard from '../../components/soluciones/doctorCard'
import ReversedDoctorCard from '../../components/soluciones/reversedDoctorCard'

const Ventsupport = ({ location }) => {

    return (
        <Layout location={location}>
            <SEO title="VentSupport" />
            <Styled>
                <div className="fondo">
                    <img className= "fondo-img1" src={backgroundDesktop} alt="logo" style={{zIndex: 1}}/>
                    <img className= "fondo-img2" src={background} alt="logo" height='430px' width='375px' />
                </div>
                <div className="info">
                    <div className="info-logo" style={{zIndex: 4}}>
                        <img src={logo} alt="logo" className="info-image"/>
                        <h3>Soporte inteligente para la ventilación mecánica</h3>
                        <p>Sistema de monitoreo de asincronías respiratorias, mechanical power y RASSCORE basado en Inteligencia Artificial</p>
                    </div>
                    <iframe width="350" height="196" src="https://www.youtube.com/embed/4ByUkwaAJCk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{zIndex: 3}}></iframe>
                </div>
                <div className="container-contact">
                    <div className="contact" style={{zIndex: 4}}>
                        <div className="span">
                            <p>Contáctanos para conocer <b>VentSupport</b> y los casos de éxito.</p>
                        </div>
                        <ContactForm page="VentSupport" location={location} paddingTitle='0px 25px' style={{zIndex: 4}} />
                    </div>
                    <div className="text">
                        <ul>
                            <li>Monitoreo 24x7 de ventiladores mecánicos en tiempo real.</li>
                            <li>Operación desde app móvil o notebook.</li>
                            <li>Detección, cuantificación y tratamiento inmediato de las asincronías respiratorias.</li>
                            <li>Alertas claves para realizar modificaciones a través de los médicos y personal de guardia.</li>
                            <li>Optimización todo el tiempo del paciente conectado.</li>
                            <li>Complementación perfecta ante weanings dificultosos y extubaciones.</li>
                        </ul>
                    </div>
                </div>
                <div className="text2">
                    <p>La transformación digital de la salud ya llegó. Hazla llegar a tu organización.</p>
                    <iframe width="350" height="196" src="https://www.youtube.com/embed/6wetYySpBcw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{zIndex: 2}}></iframe>
                    <img className="text2-img1" src={celularesDesktop} alt="logo" height='607px' width='944px' style={{zIndex: 2}} />
                    <img className="text2-img2" src={celulares} alt="logo" height='375px' width='346px' style={{zIndex: 2}} />
                    <div className="fondo2" style={{zIndex: 1}}>
                        <img className="fondo2-img1" src={background2Desktop} alt="logo" style={{zIndex: 1}} /> 
                        <img className="fondo2-img2" src={background2} alt="logo" style={{zIndex: 1}} /> 
                    </div>
                </div>
                <div className="container-cards">
                    <h3>Beneficios</h3>
                    <div className="container-cards-info" style={{zIndex: 2}}>
                        <div className='card-medicos'>
                            <h3>Médicos</h3>
                            <ul className='check-list'>
                                <li><span>Recomendaciones para la toma de mejores decisiones médicas en el ajuste del ventilador y uso de drogas.</span></li>
                                <li><span>Acceso remoto para conocer el RASSCORE del paciente.</span></li>
                                <li><span>Ver el historial de pacientes extubados para revisar caso a caso todo el proceso ventilatorio.</span></li>
                                <li><span>Pacientes en VMI en Modo Control, como en Presión Soporte, espontáneo y todos los modos ventilatorios.</span></li>
                            </ul>
                        </div>
                        <div className='card-instituciones' style={{zIndex: 2}}>
                            <h3>Instituciones</h3>
                            <ul className='check-list'>
                                <li><span>Weaning exitosos.</span></li>
                                <li><span>Menos utilización de droga.</span></li>
                                <li><span>Menos sedación.</span></li>
                                <li><span>Menos internación.</span></li>
                                <li><span>Menos óbito.</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="fondo3">
                        <img className="fondo3-desktop" src={background3Desktop} alt="logo" />
                        <img className="fondo3-mobile" src={background3} alt="logo" />
                    </div>
                    <DoctorCard />
                    <ReversedDoctorCard />
                    <div className='info-text'>
                        <h3>La IA de VentSupport realiza los cálculos comparativos, basado en 87.000 modelos de asincronías validados por expertos internacionales.</h3>
                    </div>
                    <div className="laptop">
                        <img className="laptop-img1" src={laptop} alt="logo" height='712px' width='1237px' style={{zIndex: 1}}/>
                    </div>
                </div>
            </Styled>
        </Layout>
    )
}

export default Ventsupport

const Styled = styled.section`

.fondo{
    height: 45px;
    top: -227px;
    position: relative;

    @media (max-width: 345px) {
        display: none;
    }
    
    @media (min-width: 800px) {
        top: 0px;
        height: 20vh;
    }

    .fondo-img1{
        @media (min-width: 800px) {
            width: 100%;
            height: auto;
            position: absolute;
            margin: 0px;
        }
        @media (max-width: 800px) {
            display: none;
        }
    }

    .fondo-img2{
        @media (max-width: 800px) {
            width: 100%;
            height: auto;
            position: absolute;
            margin: 0px;
            z-index: -1;
        }
        @media (min-width: 800px) {
            display: none;
        }
    }

}

.fondo2{
    width: 100%;
    position: relative;
    top: -592px;
    @media (min-width: 800px) {
        top: -1100px; 
    }

    .fondo2-img1{
        @media (min-width: 800px) {
            width: 100%;
            height: auto;
            position: absolute;
            margin: 0px;
        }
        @media (max-width: 800px) {
            display: none;
        }
    }

    .fondo2-img2{
        @media (max-width: 800px) {
            width: 100%;
            height: auto;
            position: absolute;
            margin: 0px;
        }
        @media (min-width: 800px) {
            display: none;
        }
    }
}


.fondo3 {
    width: 100%;
    position: relative;
    top: -250px;
    @media (min-width: 800px) {
        top: -300px;
    }

    .fondo3-desktop{
        z-index: -1;
        @media (min-width: 800px) {
            width: 100%;
            height: auto;
            position: absolute;
            margin: 0px;
        }
        @media (max-width: 800px) {
            display: none;
        }
    }

    .fondo3-mobile{
        z-index: -1;
        @media (max-width: 800px) {
            width: 100%;
            height: auto;
            position: absolute;
            margin: 0px;
        }
        @media (min-width: 800px) {
            display: none;
        }
    }
}

.laptop {
    margin-bottom: 120px;
    @media (max-width: 800px) {
        margin-bottom: 60px;
    }
}

.laptop-img1{
    @media (max-width: 800px) {
        display: none;
    }
}

.info {
    margin-top: 0px;

    @media (max-width: 345px) {
        margin-top: 60px;
    }
    @media (min-width: 800px) {
        display: flex;
        justify-content: space-evenly;
    }


    img {
        margin-left: 20px;
        margin-bottom: 0px;
    }

    h3 {
        color: #000000;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.38px;
        line-height: 17px;
        margin-left: 20px;

        @media (max-width: 500px) {
            font-size: 9px;
            letter-spacing: 0.3px;
        }
    }
    
    p {
        max-width: 430px;
        color: #19ADAE;
        font-family: "Century Gothic";
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 28px;
        margin: 25px;

        @media (min-width: 800px){
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 40px;
        }
    }
    iframe{
        width: 100%;
        @media (min-width: 800px){
            width: 564px !important;
            height: 326px;
            justify-content: space-evenly;
            border-radius: 20px;
            border: 8px solid #FFFFFF;
            background-color: #EBEBEB;
        }
    }
}

.info-image {
    z-index: 4;
    height: auto;
    width: 200px;

    @media (min-width: 800px){
        width: 400px;
    }
}

.container-contact{
    @media (min-width: 800px){
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-evenly;
    }
}

.span {
    display: flex;
    justify-content: center;
    @media (min-width: 800px){
        height: 64px;
        width: 545px;
    }

    p {
        width: 250px;
        font-family: "Century Gothic";
        font-size: 20px;
        letter-spacing: 0;
        line-height: 32px;
        text-align: center;
        @media (min-width: 800px){
            height: 64px;
            width: 420px;
        }
    }
}

.container-cards-info{
    @media (max-width: 800px){
        width: 100%;
    }
    @media (min-width: 800px){
        display: flex;
    }
}

.contact {
    .linkAccess {
        display: none;
    }
    .form {
        margin: 26px;
    }
    @media (min-width: 800px){
        height: 64px;
        width: 545px;
    }
}

.container-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h3{
        color: #000000;
        font-family: "Century Gothic Bold";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
        font-size: 28px;
        margin-top: 70px;
        @media (max-width: 800px){
            font-size: 20px;
            margin-top: 32px;
        }
    }

    .card-medicos{
        border-radius: 20px;
        background-color: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        margin: 20px;
        width: 435px;
        height: 463px;
        font-family: "Century Gothic";
        padding: 10px 0px;
        @media (max-width: 800px) {
            width: 343px;
            height: auto;
            margin-bottom: 40px;
        }

        h3 {
            color: #037674;
            font-family: "Century Gothic";
            font-weight: bold;
            letter-spacing: 0;
            line-height: 40px;
            text-align: center;
            margin-top: 3px;
            font-size: 24px;
            margin-bottom: 18px;
            @media (max-width: 800px) {
                font-size: 18px;
            }
        } 

        ul {
            margin-left: 48px;
        }

        li {
            list-style: none;
            color: #000000;
            font-family: "Century Gothic";
            font-size: 18px;
            letter-spacing: 0;
            line-height: 24px;
            max-width: 358px;
            @media (max-width: 800px) {
                width: 270px;
                font-size: 14px;
            }

            &:before {
                content: "•";
                color: #19ADAE;
                font-weight: bold;
                display: inline-block;
                width: 1.2em;
                margin-left: -1.2em;
                font-family: FontAwesome;
                font-size: 20px;
            }
        }
    }

    .card-instituciones{
        border-radius: 20px;
        background-color: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        margin: 20px;
        width: 435px;
        height: 463px;
        padding: 10px 0px;
        @media (max-width: 800px) {
            width: 343px;
            height: auto;
        }
        @media (max-width: 400px) {
            min-width: 100px;
        }

        h3 {
            color: #037674;
            font-family: "Century Gothic";
            font-weight: bold;
            letter-spacing: 0;
            line-height: 40px;
            text-align: center;
            margin: 3px;
            font-size: 24px;
            margin-bottom: 22px;
            @media (max-width: 800px) {
                font-size: 18px;
            }
        }

        ul {
            margin-left: 50px;
        }

        li {
            list-style: none;
            color: #037674;
            font-family: "Century Gothic";
            font-size: 24px;
            letter-spacing: 0;
            line-height: 40px;
            margin-bottom: 22px;
            @media (max-width: 800px) {
                width: 293px;
                font-size: 14px;
                margin-bottom: 16px;
            }

            &:before {
                content: "✓";
                color: #19ADAE;
                font-weight: bold;
                display: inline-block;
                width: 1.2em;
                margin-left: -1.2em;
                font-family: FontAwesome;
                font-size: 20px;
            }
        }
    }

    .container-card-medico2{
        @media (min-width: 800px){
            margin: 80px 0px; 
        }
    }

    .card-testimonio-1 {
        border-radius: 20px;
        background-color: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        margin: 20px;
        max-width: 343px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 20px;
        margin-top: 120px;
        @media (min-width: 800px){
            max-width: 700px;
            height: 480px;
            margin-left: 200px;
        }

        h3{
            color: #19AE8C;
            font-family: "Century Gothic";
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 40px;
            margin-top: 100px;
            margin-left: 20px;
            margin-bottom: 0px;
            @media (min-width: 800px){
                margin-left: 280px;
                margin-top: 50px;
            }

        }
        
        p {
            color: #000000;
            font-family: "Century Gothic";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
            margin: 0px 20px;
            @media (min-width: 800px){
                margin-left: 280px;
            }
        }

        img{
            margin: 20px;
            width: 50%;
            height: 50%;
            @media (min-width: 800px){
                margin-left: 280px;
                width: 20%;
                height: 10%;
            }
        }
    }

    .medico-1 {
        position: relative;
        bottom: -46px;
        right: -87px;
        height: 50%;

        @media (min-width: 800px){
            bottom: -115px;
            right: 6px;
        }

        img{
            max-height: 162px;
            position: absolute;
            margin: 0px;

            @media (min-width: 800px){
                max-height: 523px;
            }
        }
    }

    .medico-2 {
        position: relative;
        bottom: -46px;
        right: -87px;
        height: 50%;
        
        
        @media (min-width: 800px){
            right: -390px;
            bottom: -115px;
        }
        @media (min-width: 890px){
            right: -415px;
            bottom: -115px;
        }

        @media (min-width: 950px){
            bottom: -115px;
            right: -427px;
        }

        img{
            max-height: 162px;
            position: absolute;
            margin: 0px;

            @media (min-width: 800px){
                max-height: 523px;
            }
        }
    }

    .doctor-image {
        border-radius: 50%;
        border: 15px solid #ffffff;
    }

    .card-testimonio-2 {
        border-radius: 20px;
        background-color: #FFFFFF;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        margin: 20px;
        max-width: 343px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 20px;
        margin-top: 120px;
        
        @media (min-width: 800px){
            max-width: 700px;
            height: 480px;
            margin-right: 200px;
        }
        

        h3{
            color: #106189;
            font-family: "Century Gothic";
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 40px; 
            margin-top: 100px;
            margin-left: 20px;
            margin-bottom: 0px;
            @media (min-width: 800px){
                margin-top: 50px;
            } 
        }

        p {
            color: #000000;
            font-family: "Century Gothic";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
            margin: 0px 20px;
            @media (min-width: 800px){
                margin-right: 13rem;
            }
            @media (min-width: 846px){
                margin-right: 16rem;
            }
            @media (min-width: 890px){
                margin-right: 17rem;
            } 
            @media (min-width: 950px){
                margin-right: 20rem;
            } 
        }

        img {
            margin: 20px;
            width: 70%;
            height: 70%;
            @media (min-width: 800px){
                margin-right: 250px;
                width: 30%;
                height: 10%;
            }
        }
    }

    .info-text {
        @media (min-width: 800px){
            min-width: 700px;
            margin: 60px 20px 60px 20px; 
            max-width: 1000px;
        }
        h3 {
            color: #19ADAE;
            font-family: "Century Gothic";
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 28px;
            text-align: center;
            padding: 20px;
            @media (min-width: 800px){
                font-size: 28px;
                line-height: 40px;
            }
        }
    }
}

.text2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 800px) {
        margin-top: 60px;
    }

    p {
        color: #000000;
        font-family: "Century Gothic";
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
        margin: 2rem;
        @media (max-width: 800px) {
            font-size: 20px;
        }

    }
    iframe{
        width: 100%;
        @media (min-width: 800px){
            width: 564px !important;
            height: 326px;
            justify-content: space-evenly;
            border-radius: 20px;
            border: 8px solid #FFFFFF;
            background-color: #EBEBEB;
        }
    }
    .text2-img1 {
        margin-top: 120px;
        @media (max-width: 800px) {
            display: none;
        }
    }
    .text2-img2 {
        margin-top: 32px;
        @media (min-width: 800px) {
            display: none;
        }
    }

}

.text {
    margin: 16px;
    font-family: "Century Gothic";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;

    @media (min-width: 800px){
        font-size: 18px;
        max-width: 400px;
        margin-top: 50px;
    }

    ul {

        list-style: none;

        li {
            max-width: 317px;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0;
            line-height: 24px;
            @media (min-width: 800px){
                font-size: 18px;
                max-width: 400px;
            }

        &:before {
            content: "•";
            color: #19ADAE;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
    
}

.check-list {
    list-style: none;
    margin-top: 16px;

    li {
        &:before {
        content: "✓";
        color: #915CFD;
        font-weight: bold;
        display: inline-block;
        width: 1.2em;
        margin-left: -1.2em;
        font-family: FontAwesome;
        font-size: 20px;
      }
    }
  }
`

/*
        li {
            color: #19ADAE;
            margin-left: 10px;
            margin-right: 10px;
            font-family: "Century Gothic";

            span{
                color: #000000;
                font-family: "Century Gothic" !important;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 24px;
                @media (min-width: 800px){
                    font-size: 18px;
                    font-family: "Century Gothic" !important;
                }
            }
        }


        li {
            color: #037674;
            margin-left: 10px;
            padding: 20px 0px;
            @media (max-width: 400px) {
                margin: 0px 100px 0px 15px;
            }

            span{
                color: #037674;
                font-family: "Century Gothic";
                font-size: 21px;
                letter-spacing: 0;
                line-height: 24px;
            }
        }
*/