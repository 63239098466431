import React from "react"
import styled from "styled-components"
import medico2 from "../../images/source/vent-medico-2.png"
import logoFavaloro from "../../images/source/logo-favaloro1.png"
import CombinedShape from "../../images/source/CombinedShape.svg"
import MobileShape2 from "../../images/source/MobileShape2.png"

const ReversedDoctorCard = () => {
	return (
    <Container>
      <div className="container">
        <div className="container2">
          <CombinedShape className="shape2" />
          <img src={MobileShape2} alt="card" className="shape-mobile" />

          <div className='box overlay'>
            <h4 className="testimonios">Testimonios</h4>
            <p className="vent">
              “Por medio de este software hemos podido integrar avances de la tecnología actual al campo médico 
              y a la labor diaria en la terapia intensiva. El beneficio de poder monitorizar a los pacientes en 
              ARM en nuestro smartphone de manera remota, nos fue de mucha ayuda en la pandemia Covid-19, pudimos 
              ser capaces de asesorar a nuestros compañeros de guardia y alertar de asincronías para su pronta 
              corrección, ayudándonos a reducir los tiempos de ventilación mecánica y así mejorando el pronóstico 
              de los pacientes internados.”
            </p>
            <img src={logoFavaloro} alt="logo trinidad" className="logo" />
            <p className="foot-text">
            Dra. Flosmary Colina Reyes <br />
            MN: 553789 - Médica Terapia Intensiva
            </p>
          </div>
          <img src={medico2} alt="doctor img" className='doc-img overlay'/>
        </div>
      </div>
    </Container>
	)
}

export default ReversedDoctorCard

const Container = styled.footer`
  .container {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  .container2 {
    position: relative;
  }

  .box {
    top: 84px;
    left: 75px;
    height: 445px;
    width: 482px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 800px) {
      top: 162px;
      left: 30px;
      width: 303px;
      height: 480px;
    }
  }
  
  .overlay {
    z-index: 9;
  }

  .doc-img {
    top: 45px;
    left: 608px;
    height: 508px;
    width: 508px;
    position: absolute;
    @media (max-width: 800px) {
      left: 109px;
      top: 15px;
      height: 134px;
      width: 134px;
    }
  }

  .testimonios {
    height: 40px;
    width: 400px;
    color: #106189;
    font-family: "Century Gothic";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin: 0;
    @media (max-width: 800px) {
      font-size: 18px;
    }
  }

  .vent {
    color: #000000;
    font-family: "Century Gothic";
    font-style: italic;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .logo {
    margin: 0;
    width: 280px;
    @media (max-width: 800px) {
      width: 250px;
    }
  }

  .foot-text {
    height: 61px;
    width: 482px;
    color: #615E5E;
    font-family: "Century Gothic";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    max-width: fit-content;
    @media (max-width: 800px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .shape2 {
    transform-origin: center;
    transform: scale(-1, 1);
    @media (max-width: 800px) {
      display: none;
    }
  }

  .shape-mobile {
    @media (min-width: 800px) {
      display: none;
    }
  }
`
